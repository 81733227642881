/* eslint-disable camelcase */
import client from './http';

class ReportService {

  constructor(axios) {
    this.axios = axios;
  }
  getDailyLoginsChartData(programId, group_id) {
    if(group_id) {
      return this.axios.get(`/programs/${programId}/reports/logins/charts/daily`, {params: { group_id }});
    }
    return this.axios.get(`/programs/${programId}/reports/logins/charts/daily`);
  }
  getMonthlyLoginsChartData(programId, search, to, from, group_id) {
    return this.axios.get(`/programs/${programId}/reports/logins/charts/monthly`,  {params: { search , to, from, group_id }});
  }
  getLoginsList(programId, search, to, from, group_id) {
    return this.axios.get(`/programs/${programId}/reports/logins`,  {params: { search , to, from, group_id }});
  }

  getLearningsList(programId, search, to, from, group_id) {
    return this.axios.get(`/programs/${programId}/reports/training-completions`,  {params: { search , to, from, group_id }});
  }
  getDailyLearningsChartData(programId, group_id) {
    if(group_id) {
      return this.axios.get(`/programs/${programId}/reports/training-completions/charts/daily`, {params: { group_id }});
    }
    return this.axios.get(`/programs/${programId}/reports/training-completions/charts/daily`);
  }
  getMonthlyLearningsChartData(programId, search, to, from, group_id) {
    return this.axios.get(`/programs/${programId}/reports/training-completions/charts/monthly`,  {params: { search , to, from, group_id }});
  }
  getMonthlyMatchesChartData(programId, search, to, from, group_id) {
    return this.axios.get(`/programs/${programId}/reports/matches/charts/monthly`,  {params: { search , to, from, group_id }});
  }
  getLearningsList(programId, search, to, from, group_id) {
    return this.axios.get(`/programs/${programId}/reports/training-completions`,  {params: { search , to, from, group_id }});
  }
  getDailyDownloadsChartData(programId, group_id) {
    if(group_id) {
      return this.axios.get(`/programs/${programId}/reports/downloads/charts/daily`, {params: { group_id }});
    }
    return this.axios.get(`/programs/${programId}/reports/downloads/charts/daily`);
  }
  getMonthlyDownloadsChartData(programId, search, to, from, group_id) {
    return this.axios.get(`/programs/${programId}/reports/downloads/charts/monthly`,  {params: { search , to, from, group_id }});
  }  
  getWeeklyTraineeCompletionChartData(programId, search, to, from, group_id) {
    return this.axios.get(`/programs/${programId}/reports/training-completions/charts/weekly`,  {params: { search , to, from, group_id }});
  }  
  getDownloadsList(programId, search, to, from, group_id) {
    return this.axios.get(`/programs/${programId}/reports/downloads`,  {params: { search , to, from, group_id }});
  }
  getDailyMessagesData(programId, group_id) {
    if(group_id) {
      return this.axios.get(`/programs/${programId}/reports/messages/charts/daily`, {params: { group_id }});
    }
    return this.axios.get(`/programs/${programId}/reports/messages/charts/daily`);

  }
  getMonthlyMessagesDownloadsChartData(programId, search, to, from, group_id) {
    return this.axios.get(`/programs/${programId}/reports/messages/charts/monthly`,  {params: { search , to, from, group_id }});
  }
  getMessagesList(programId, search, to, from, group_id) {
    return this.axios.get(`/programs/${programId}/reports/messages`,  {params: { search , to, from, group_id }});
  }
  getUsersActivityLogs(programId, userId, search) {
    return this.axios.get(`/programs/${programId}/users/${userId}/activity-logs`,  {params: { search }});
  }
  getSessionActivityLog(programId, loginId, search) {
    return this.axios.get(`/programs/${programId}/reports/logins/${loginId}/activity`,  {params: { search }});
  }
  getAdminDashboard() {
    return this.axios.get(`/dashboard`);
  }
  getReportSegments(programId) {
    return this.axios.get(`/programs/${programId}/report-segments`);
  }
  getPublicReportSegments(token) {
    return this.axios.get(`/programs/report-segments/${token}`);
  }
  updateProgramGraphSummary(programId, data) {
    return this.axios.post(`/programs/${programId}/report-graphs/summary`, data);
  }
  updateProgramSegmentSummary(programId, data) {
    return this.axios.post(`/programs/${programId}/report-segments/summary`, data);
  }
}

const reportService = new ReportService(client);
export default reportService;
