import client from './http';

class ProgramsService {

  constructor(axios) {
    this.axios = axios;
  }

  getList(search) {
    return this.axios.get('/programs', {params: {search: JSON.stringify(search)}});
  }
  getListTemplates(search, params = {}) {
    return this.axios.get('/program-templates', {params: {search: JSON.stringify(search), ...params}});
  }

  create(clientId, data) {
    return this.axios.post(`/clients/${clientId}/programs`, data);
  }
  createTemplate(data) {
    return this.axios.post(`/program-templates`, data);
  }
  updateTemplate(programId, data) {
    return this.axios.patch(`/program-templates/${programId}`, data);
  }

  createDemo(clientId, applicationSetId, surveyId, defaultChampionId) {
    return this.axios.post(
      `/clients/${clientId}/programs/demo`, 
      {client_id: clientId, application_set_id: applicationSetId, survey_id: surveyId, default_champion_id: defaultChampionId}
    );
  }

  getClientPrograms(clientId, search) {
    let options = undefined;
    if (search) {
      options = {params: {search}};
    }
    return this.axios.get(`/clients/${clientId}/programs`, options);
  }

  getClientUserPrograms(clientId, userId, search) {
    let options = undefined;
    if (search) {
      options = {params: {search }};
    }
    return this.axios.get(`/clients/${clientId}/users/${userId}/programs`, options);
  }

  getProgramByClientSubdomainAndProgramPath(subdomain, path) {
    return this.axios.get(`/clients/${subdomain}/programs/${path}`);
  }

  saveSamlSettings(programId, data) {
    return this.axios.post(`/programs/${programId}/sso/saml`, data);
  }

  getProgramByClientIdProgramId(clientId, programId) {
    return this.axios.get(`/clients/${clientId}/programs/${programId}`);
  }

  updateProgram(programId, data) {
    return this.axios.patch(`/programs/${programId}/`, data);
  }

  updateProgramIntegrations(programId, data) {
    return this.axios.patch(`/programs/${programId}/integrations`, data);
  }

  updateProgramReminders(programId, data) {
    return this.axios.patch(`/programs/${programId}/reminders`, data);
  }

  getProgramReminderSubjects() {
    return this.axios.get(`/reminder-subjects`);
  }

  updateProgramReportSections(programId, data) {
    return this.axios.patch(`/programs/${programId}/report-sections`, data);
  }

  updateProgramReportSegments(programId, data) {
    return this.axios.patch(`/programs/${programId}/report-segments`, data);
  }

  getProgramReportConfig(programId) {
    return this.axios.get(`/programs/${programId}/report-config`);
  }

  getProgramByProgramId(programId) {
    return this.axios.get(`/programs/${programId}`);
  }

  deleteProgram(programId) {
    return this.axios.delete(`/programs/${programId}`);
  }

  getProgramSummaryForParticipant(programId) {
    return this.axios.get(`/programs/${programId}/dashboards/participant`);
  }

  removeApplicationSet(programId, applicationId) {
    return this.axios.delete(`/programs/${programId}/application-sets/${applicationId}`);
  }

  getApplicationSetsByProgramId(programId, search) {
    return this.axios.get(`/programs/${programId}/application-sets`, { params: { search: search } });
  }

  getApplicationSetByProgramId(programId, applicationSetId) {
    return this.axios.get(`/programs/${programId}/application-sets/${applicationSetId}`);
  }

  addApplicationSetsToProgram(programId, data) {
    return this.axios.post(`/programs/${programId}/applications/add`, data);
  }

  getSurveysByProgramId(programId, search) {
    return this.axios.get(`/programs/${programId}/surveys`, { params: { search: search } });
  }

  getSurveysByProgramIdAndUserId(programId, userId) {
    return this.axios.get(`/programs/${programId}/users/${userId}/surveys`);
  }

  getHealthAlertsByProgramId(programId) {
    return this.axios.get(`/programs/${programId}/health-alerts`);
  }

  addSurveyToProgram(programId, data) {
    return this.axios.post(`/programs/${programId}/surveys/add`, data);
  }

  getSurveyAnswersByProgramId(params) {
    if (params.matchId) {
      return this.axios.get(`/programs/${params.programId}/users/${params.userId}/matches/${params.matchId}/surveys/${params.formId}/answers`);
    } else {
      return this.axios.get(`/programs/${params.programId}/users/${params.userId}/surveys/${params.formId}/answers`);
    }
  }

  getSurveyAnswersByToken(params) {
    if (params.matchId) {
      return this.axios.get(`/matches/${params.matchId}/surveys/${params.formId}/answers/tokens/${params.token}`);
    } else {
      return this.axios.get(`/surveys/${params.formId}/answers/tokens/${params.token}`);
    }
  }

  saveOwnSurveyAnswers(params, data) {
    if (params.matchId) {
      return this.axios.post(`/programs/${params.programId}/users/${params.userId}/matches/${params.matchId}/surveys/${params.formId}/answers`, data);
    } else {
      return this.axios.post(`/programs/${params.programId}/users/${params.userId}/surveys/${params.formId}/answers`, data);
    }
  }

  saveOwnSurveyAnswersByToken(params, data) {
    if (params.matchId) {
      return this.axios.post(`/matches/${params.matchId}/surveys/${params.formId}/answers/tokens/${params.token}`, data);
    } else {
      return this.axios.post(`/surveys/${params.formId}/answers/tokens/${params.token}`, data);
    }
  }

  // Program Notes
  getNoteListByUser(programId, userId) {
    return this.axios.get(`/programs/${programId}/users/${userId}/notes`);
  }

  getTags(programId, userId) {
    return this.axios.get(`/programs/${programId}/tags`);
  }

  updateTags(programId, data) {
    return this.axios.patch(`/programs/${programId}/tags`, data);
  }

  getNoteByUser(programId, userId, noteId) {
    return this.axios.get(`/programs/${programId}/users/${userId}/notes/${noteId}`);
  }

  createNote(programId, userId, data) {
    return this.axios.post(`/programs/${programId}/users/${userId}/notes`, data);
  }

  updateNote(programId, userId, noteId, data) {
    return this.axios.patch(`/programs/${programId}/users/${userId}/notes/${noteId}`, data);
  }

  deleteNote(programId, userId, noteId) {
    return this.axios.delete(`/programs/${programId}/users/${userId}/notes/${noteId}`);
  }

  // Transfer
  transferParticipantsFromAnotherProgram(programId, data) {
    return this.axios.post(`/programs/${programId}/users/transfer`, data);
  }
  
  shareReport(programId, data) {
    return this.axios.post(`/programs/${programId}/reports/share`, data);
  }
}

const programsService = new ProgramsService(client);
export default programsService;
